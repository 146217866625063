<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">入库验车</div>
				<div class="header-button">
					<el-button type="success" @click="onCreate" v-permission="'pc/car-center/in-store/save'">
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button>
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/car-center/in-store/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="setting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					autoWidth
				>
					<template #carModelName="{ value, row }">
						<span class="click" @click="onDetail(row)">{{ value }}</span>
					</template>
					<template #status="{ value }">
						<span>{{ mapDict('car_inspect_status', value) }}</span>
					</template>
					<template #inspectResult="{ row }">
						<span>{{ mapDict('inspectResult', row.inspectResult) }}</span>
					</template>
					<template #operation="{ row }">
						<span v-permission="'pc/car-center/in-store/edit'" class="click" @click="onEdit(row)">
							编辑
						</span>
						<span
							v-permission="'pc/car-center/in-store/cancel'"
							class="click"
							@click="onCancel(row)"
						>
							作废
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<check-form ref="formRef" @on-refresh="onRefresh" @on-delivery="onDelivery" />
		<delivery-form ref="deliveryRef" />
	</div>
</template>

<script name="VehicleCheck">
import dayjs from 'dayjs'
import { ref, defineComponent, inject, onActivated, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { setting as s, columns } from './config'
import { InitTable, useFilter, useDict } from '@/utils/hooks.js'
import { handleExport } from '@/utils/util'
import { ElMessageBox } from 'element-plus'
import {
	assetCarStockInList,
	storageSelect,
	checkUserList,
	exportStockIn,
	companySelect,
	cancelCarStockIn,
} from '@/api/vehicle.js'
import CheckForm from './form'
import DeliveryForm from '../delivery/form'

export default defineComponent({
	name: 'VehicleCheck',
	components: {
		CheckForm,
		DeliveryForm,
	},
	setup() {
		const $message = inject('$message')
		const formRef = ref(null)
		const deliveryRef = ref(null)
		const loading = ref(null)
		const params = {
			inspectTimeStart: dayjs().subtract(1, 'month').format('YYYY-MM-DD') + ' 00:00:00',
			inspectTimeEnd: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
		}
		const dict = ref({
			inspectResult: [
				{ name: '无需维修', code: 0 },
				{ name: '需要维修', code: 1 },
			],
		})
		const service = assetCarStockInList
		const setting = ref(s)
		const table = new InitTable(service, params)
		const findItem = key => {
			return setting.value.filters.find(item => item.attr === key)
		}
		const methods = {
			onChangeFilter(params) {
				const date = {}
				;['inspectTime'].forEach(key => {
					const data = params[key]
					if (Array.isArray(data) && data.length) {
						const [start, end] = data
						date[`${key}Start`] = start
						date[`${key}End`] = end
					} else {
						delete params[`${key}Start`]
						delete params[`${key}End`]
					}
				})
				table.onChangeFilter({ ...params, ...date })
			},
			onCreate() {
				formRef.value.open()
			},
			onEdit(row) {
				const { status } = row
				if (status !== 1) {
					return $message.error('当前车辆状态不是待结算，不允许编辑验车单')
				}
				formRef.value.open(row.id)
			},
			onCancel(row) {
				const { id, status } = row
				if (status !== 1) {
					return $message.error('当前车辆状态不是待结算，不允许作废')
				}
				ElMessageBox.confirm('确认作废该条记录?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(async () => {
						const res = await cancelCarStockIn(id)
						if (res.code !== 200) return $message.error(res.message)
						$message.success('操作成功')
						table.fetchList()
					})
					.catch(() => {})
			},
			onDetail(row) {
				formRef.value.open(row.id, 'detail')
			},
			onDelivery(id) {
				deliveryRef.value.open(id, 'detail')
			},
			onRefresh() {
				table.fetchList()
			},
			mapDict(key, value) {
				const e = dict.value[key]
				if (!e) return '--'
				const target = e.find(item => item.code == value)
				return target ? target.name : '--'
			},
			async onExport() {
				loading.value = true
				try {
					const res = await exportStockIn({ ...table.params })
					if (res) handleExport(res, '入库验车')
				} catch (err) {
					$message.error(err.message)
				} finally {
					loading.value = false
				}
			},
			async getList() {
				const res = await storageSelect(20, { isPermission: true })
				if (res.code === 200) {
					const storage = setting.value.filters.find(item => item.attr === 'carStorageId')
					storage.options = res.data.map(item => ({ name: item.name, code: item.id }))
				}
			},
			async getUserList() {
				const res = await checkUserList()
				if (res.code === 200) {
					const createUser = setting.value.filters.find(item => item.attr === 'createUser')
					createUser.options = res.data.map(item => ({ name: item.userName, code: item.userId }))
				}
			},
			async fetchSelect() {
				const { data, err } = await useFilter(() => companySelect(true), {
					name: 'companyName',
					code: 'companyId',
				})
				if (err) $message.error(err.message)
				const company = setting.value.filters.find(item => item.attr === 'belongCompanyIds')
				company.options = data
			},

			async fetchDict() {
				const dicts = await useDict('car_inspect_status')
				dict.value.car_inspect_status = dicts['car_inspect_status']
				const fuelTypes = findItem('status')
				fuelTypes.options = dicts['car_inspect_status']
			},
		}
		onActivated(() => {
			const route = useRoute()
			const query = route.query
			let data = {}
			if (query && query.inspectTime) {
				const [start, end] = query?.inspectTime?.split(',') || []
				data = {
					inspectTime: [`${start} 00:00:00`, `${end ? end : start} 23:59:59`],
				}
			} else {
				data = { inspectTime: [params.inspectTimeStart, params.inspectTimeEnd] }
			}
			data.belongCompanyIds = query?.companyIds?.split(',') || []
			const ins = getCurrentInstance()
			ins.refs.filterRef && ins.refs.filterRef.manualSetParams(data)
			methods.getList()
			methods.getUserList()
			methods.fetchSelect()
			methods.fetchDict()
		})
		methods.onRefresh()
		methods.getList()
		methods.getUserList()
		methods.fetchSelect()
		methods.fetchDict()
		return {
			setting,
			columns,
			formRef,
			deliveryRef,
			loading,
			...table.res,
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.click {
		cursor: pointer;
		color: #409eff;
	}
}
</style>
