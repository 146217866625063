export const columns = [
	{
		prop: 'carModelName',
		label: '车型名称',
		width: '280px',
	},
	{
		prop: 'carNum',
		label: '自编号',
		width: '140px',
	},
	{
		prop: 'licensePlateNum',
		label: '车牌号',
		width: '120px',
	},
	{
		prop: 'carColor',
		label: '颜色',
	},
	{
		prop: 'maintainerName',
		label: '维保专员',
		width: '120px',
	},
	{
		prop: 'createUserName',
		label: '验车负责人',
		width: '120px',
	},
	{
		prop: 'createTime',
		label: '验车时间',
		width: '220px',
	},
	{
		prop: 'carMileage',
		label: '公里数（KM）',
		width: '140px',
	},
	{
		prop: 'status',
		label: '验车状态',
		width: '100px',
	},
	{
		prop: 'inspectResult',
		label: '验车结果',
		width: '100px',
	},
	{
		prop: 'carStorageName',
		label: '库存地',
		width: '200px',
	},
	{
		prop: 'damageAmount',
		label: '质损金额',
		width: '100px',
	},
	{
		prop: 'belongCompanyName',
		label: '所属公司',
		width: '280px',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '120px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'keyword',
			label: '车辆信息',
			placeholder: '输入车型名称、车牌、自编号搜索',
			type: 'search',
			width: 350,
		},
		{
			attr: 'createUser',
			label: '验车负责人',
			placeholder: '请选择',
			type: 'select',
			options: [],
		},
		{
			attr: 'inspectResult',
			label: '验车结果',
			placeholder: '请选择',
			type: 'select',
			options: [
				{ name: '无需维修', code: 0 },
				{ name: '需要维修', code: 1 },
			],
		},
		{
			attr: 'status',
			label: '验车状态',
			placeholder: '请选择验车状态',
			type: 'select',
			options: [],
		},
		{
			attr: 'belongCompanyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '选择所属公司',
			width: 320,
			options: [],
		},
		{
			attr: 'carStorageId',
			label: '库存地',
			placeholder: '请选择库存地',
			type: 'select',
			options: [],
		},
		{ attr: 'inspectTime', label: '验车时间', placeholder: '请选择验车时间', type: 'date' },
	],
}
